import React, { Component } from 'react'
import styled, { keyframes }  from 'styled-components'
import musicdata from '../../musicdata.json'
import HeroImage from '../components/heroImage'
import LargeImage from '../components/largeImage'
import HeroCaseStudy from '../components/heroCaseStudy'
import TextBlockCentered from '../components/textBlockCentered'
import TextBlockRight from '../components/textBlockRight'
import IntroCaseStudy2 from '../components/introCaseStudy-2col'
import FooterCaseStudy from '../components/footerCaseStudy';

import Layout from "../components/layout"

const slideUp = keyframes`
  0% {
      transform: translateY(-50px)
  }
  100% {
      transform: translateY(0px)
  }
`

const SectionHeroCaseStudy = styled.div`
width: 100%;

  & before {
  content:'';
  height: 0vh;
  width: 100vw;
  background-color: #0065ff;
  position:absolute;
  bottom:0;
  z-index: 100;  
  animation: ${slideUp} 1s forwards cubic-bezier(0.2, 0.8, 0.2, 1.0);
  }
`
const SectionIntroCaseStudy = styled.div`
width: 100%;
`
const SectionTextBlockCentered = styled.div`
width: 100%;
`


export default class Sevenhugs extends Component {
	render () {
		return (
		<Layout>
			<div>
				<SectionHeroCaseStudy>
					{musicdata.heroCaseStudy.map(cell => (
						<HeroCaseStudy title={cell.title} 
						subtitle={cell.subtitle}
						image={cell.image}/>
					))}
				</SectionHeroCaseStudy>
				<SectionIntroCaseStudy>
					{musicdata.introCaseStudy2.map(cell => (
						<IntroCaseStudy2 title1={cell.title1} 
						description1={cell.description1} title2={cell.title2} 
						description2={cell.description2}
						/>
					))}
				</SectionIntroCaseStudy>
				{musicdata.largeImage.map(cell => (
					<LargeImage image={cell.image}></LargeImage>
				))}
				<SectionTextBlockCentered>
					{musicdata.textBlockCentered.map(cell => (
					<TextBlockCentered title={cell.title} paragraph={cell.paragraph} image={cell.image} image2={cell.image2} image3={cell.image3} video={cell.video}/>
					))}
				</SectionTextBlockCentered>
				<FooterCaseStudy />
			</div>
		</Layout>
		)
	}
}